import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/contactForm";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import OSSection from "../../components/other-services-section";

const SeoAuditPageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpSeoPage(title: { eq: "SEO Audit Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}

			jasonImg: wpMediaItem(title: { eq: "jasoncornes-image-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "seoaudit" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoTopImg: wpMediaItem(title: { eq: "Demystifying SEO presentation" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "SEO Audit",
				item: {
					url: `${siteUrl}/dubai/seo-audit`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/seo-audit`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Audit my website"
						button1Link="#book-now"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`SEO AUDIT \n<span class="text-secondary">DUBAI</span>`}
						description="Boost your business with an SEO audit."
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#what-it-covers"
								>
									What it covers
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#book-now"
								>
									Book now
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={5} className=" ">
								<h2 className="display-5 text-primary mb-5">
									How can an SEO audit help you?
								</h2>
								<p>
									By conducting an SEO audit, you can gain meaningful insights
									into your website's performance on Google, as well as
									understanding the business benefits such as how ranking higher
									on Google can increase website traffic and generate more
									revenue.
								</p>
								<p>
									This audit serves as a valuable tool for improving your
									website's search engine ranking, driving traffic, and
									ultimately boosting sales and leads. Our team will identify
									areas where your website can be optimised to improve how
									Google perceives it, and we'll develop a plan to increase its
									ranking. Get your free SEO audit today!
								</p>
							</Col>
							<Col lg={7}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.seoTopImg.gatsbyImage}
										alt={data.seoTopImg?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(58deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-9 "
				>
					<Container>
						<Row className=" align-items-center">
							<Col lg={5} className="position-relative ps-6">
								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 30px #00000029",
										height: "30rem",
										overflow: "hidden",
									}}
									className="bg-white me-auto   w-50"
								>
									<GatsbyImage
										className="w-100 "
										image={data.jasonImg.gatsbyImage}
										alt={data.jasonImg?.altText}
										objectPosition="top center"
									/>
								</div>

								<div
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 30px #00000029",
										overflow: "hidden",
									}}
									className="bg-white position-absolute end-20 bottom-10   w-50"
								>
									<GatsbyImage
										className="w-100 "
										image={data.seoImg.gatsbyImage}
										alt={data.seoImg?.altText}
										objectPosition="top center"
									/>
								</div>
							</Col>
							<Col lg={7}>
								{/* <h2 className=" ssp-bold text-white mb-5 mt-5 mt-lg-0">
									Our free consultation of technical and content SEO
									improvements you can make to your website
								</h2> */}
								<p className="text-white mb-5">
									Receive a complimentary SEO audit and consultation by
									submitting your company's details below. Our team will reach
									out to you as soon as possible to schedule a discovery meeting
									or, if you prefer, you can choose a date that works best for
									you via our online calendar.
								</p>
								<ContactForm extraButton />
							</Col>
						</Row>
					</Container>
				</section>

				<section id="what-it-covers" className="py-5 py-lg-7 bg-light-grey">
					<Container>
						<Row>
							<Col>
								{" "}
								<h2 className="display-5 text-primary ssp-bold mb-7">
									FREE SEO AUDIT FOR DUBAI BUSINESSES
								</h2>
							</Col>
						</Row>
						<Row className="g-lg-6 g-5">
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-1.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Keyword Analysis
								</h3>
								<p>
									Our team will perform a comprehensive analysis of keywords,
									including their search volume, to identify the most effective
									keywords for your business to target.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-2.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">Page speed</h3>
								<p>
									A slow loading website can negatively impact your rankings.
									Our audit will analyse how long your website takes to load and
									how this will affect your users' experience.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-3.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Backlink analysis
								</h3>
								<p>
									Poor backlinks can penalise your website, especially if they
									are from spam sites. Our team will assess the links to your
									website and identify any harmful ones which should be removed.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-4.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Competitor analysis
								</h3>
								<p>
									Understand how your competitors use SEO to get website traffic
									and identify key areas where you can rank higher. 
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-5.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">Technical SEO</h3>
								<p>
									By understanding the impact that back-end SEO code such as
									Schema markup has on your webpages, you will improve the
									success of your website on search engine results pages.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-6.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Reporting & analytics
								</h3>
								<p>
									During our audit, we will review your website analytics to
									provide you with a better understanding of where you are
									ranking currently.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-7.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Information architecture
								</h3>
								<p>
									We will review how your website and landing pages are
									structured and whether they follow Google’s best practices.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-8.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className=" ssp-bold text-primary my-3">
									Content marketing strategy
								</h3>
								<p>
									Content is fundamental to SEO success. We will identify gaps
									within your content or whether you have too much content about
									a particular subject that is cannibalising your rankings. 
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="book-now" className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								{" "}
								<h2 className="display-5 text-primary mb-5">
									Book your free SEO audit now
								</h2>
								<p>
									Simply enter your details below and we will email you our
									report. Don't forget to book in a free consultation with one
									of our team, so we can talk through our recommendations for
									your business.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/seo-audit?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/seo-audit?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>

				<div id="faq">
					<Faq page="SEO Audit" />
				</div>
				<OSSection
					dubai
					link3="/dubai/web-design"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/>
			</Layout>
		</>
	);
};

export default SeoAuditPageDubai;
